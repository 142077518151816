﻿@import "~bootstrap/scss/variables";

.latest-news {
    .card {
        .card-text {
            font-size: 0.8rem;
        }

        .card-footer {
            padding: 0;

            a {
                width: 100%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                text-align: end;
                text-transform: uppercase;
            }
        }
    }
}

.author-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-container {
        width: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .author-details {
        .author-name {
            font-weight: 700;
            font-size: 1.3rem;
            padding: 1rem 0;
            margin: 0;
        }
    }
}
