﻿.team {
    .image-container {
        width: 80%;
        border-radius: 0.25rem;
        height: 360px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
