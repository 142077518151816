﻿.main-nav{
    z-index: 999888;

    .navbar-logo {
        position: absolute;
        top: 0;
    }

    .navbar-text {
        margin-left: 120px;
        color: white !important;
    }

    .top-nav {
        min-height: 40px;
    }

    .bottom-nav {
        min-height: 50px;
    }
}
.rssw__overlay__inner{
    z-index: 999999;
    max-height: 80%;
    top: 10%;
}

