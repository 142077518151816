﻿.accreditations{

    .cred-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .cred-item{
            padding: 1rem;
            margin: 1rem;

            img{
                max-height: 100px;
                width: 100%;
            }
        }
    }
}