﻿.benefits {
    .benefits_card {
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 100%;

        .subtitle {
            text-transform: uppercase;
        }

        h3 {
            padding-top: 1rem;
            font-size: 1.5rem;
        }

        p {
            font-size: 0.8rem;
        }
    }
}
