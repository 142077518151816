﻿.services {
    .service-card {
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .subtitle {
            text-transform: uppercase;
        }

        h3 {
            padding-top: 1rem;
            font-size: 1.5rem;
        }

        p {
            font-size: 0.8rem;
        }
        a {
           text-decoration: none;
        }
    }

    .services-grid {
        .service-item {
            padding: 1rem;
            text-align: center;

            .card {
                height: 100%;
                min-height: 200px;
                border-width: 0;

                .card-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                &.flip-card {
                    perspective: 1000px;

                    .card-body {
                        /*transform-style: preserve-3d;*/
                        position: relative;
                        /* Position the front and back side */
                        .card-body-front, .card-body-back {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            -webkit-backface-visibility: hidden; /* Safari */
                            backface-visibility: hidden;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 1rem;
                            border-radius: 0.25rem;
                        }
                        /* Style the front side (fallback if image is missing) */
                        .card-body-front {
                            background-color: rgba($primary, 0.8);
                            color: white;
                            z-index: 1;
                        }
                        /* Style the back side */
                        .card-body-back {
                            z-index: 2;
                            opacity: 0;
                            transition: all 0.3s;
                            background-color: $info;
                            color: $light;
                            /*transform: rotateY(180deg);*/
                        }
                    }

                    &:hover {
                        .card-body {
                            /*transform: rotateY(180deg);*/
                            .card-body-back {
                                opacity: 100%;
                            }
                        }
                    }
                }
            }

            &:nth-child(even) .flip-card .card-body-back {
                background-color: $warning !important;
                color: $dark !important;
            }
        }
    }
}
