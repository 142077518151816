﻿.careers {
    .careers-group {

        .careers-item {
            &:first-child {
                padding-top: 0;
            }

            padding-top: 2rem;
            text-align: justify;

            h3 {
                padding-bottom: 1rem;
            }
        }
    }
}
