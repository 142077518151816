﻿@import "~bootstrap/scss/variables";

footer {
    padding: 7em 0;
    background-color: $primary;
    color: $white;

    .list-group-item {
        background-color: unset;
        color: $gray-500;
        padding-left: 0;

        a {
            color: $gray-500;
            text-decoration: none;

            &:hover {
                color: $white;
            }
        }
    }

    .footer-heading {
        font-size: 1.3rem;
        padding-bottom: 1rem;
    }

    .footer-social {
        display: flex;

        .footer-social-item {
            font-size: 2rem;
            padding-right: 1rem;
            list-style: none;


            i, svg {
                color: $gray-500;
            }

            &:last-child {
                padding-right: 0;
            }

            &:hover {
                i, svg {
                    color: $white;
                }
            }
        }
    }

    .developers{
        a {
            text-decoration: none;
            color: white;
        }
    }
}