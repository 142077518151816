﻿.hero {
    margin-top: -10px;
    position: relative;

    .upper {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .inner {
            display: flex;
            justify-content: space-between;
            position: relative;

            .left {
                display: flex;
                flex-direction: column;
                margin: 72px 24px;
                height: 600px;
                color: $white;

                .inner-box {
                    /*background: rgba($primary, 0.8);*/
                    width: 70%;
                    padding: 1rem;

                    h1 {
                        padding-top: 2rem;
                        padding-bottom: 3rem;
                        font-size: calc(1.375rem + 1.5vw);
                    }

                    .strapline {
                        font-size: 1.6rem;
                    }
                }
            }

            .right {
                position: relative;
            }
        }

        .hero-form {
            background-color: $gray-400;
            border-radius: 10px;
            max-width: 372px;
            position: absolute;
            top: 100px;
            right: 0;
            z-index: 9999;

            h5 {
                font-size: 1.5rem;
                padding: 0 0.4rem;
            }

            p.text {
                padding: 0 0.4rem;
                font-size: 0.9rem;
            }

            .top {
                padding: 1rem;
            }

            .bottom {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 1rem;
                background: $primary;
            }
        }
    }

    .lower {
        height: 130px;

        .cred-container {
            height: 130px;

            .cred-item {
                width: 130px;
                margin-right: 20px;
            }
        }
    }
}


.hero-page {
    margin-top: -10px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .overlay-box{
        color: white;
        padding-top: 10px;

        &.bg-primary {
            // TODO: Need SCSS mixin to automate this for all colors
            background-color: rgba($primary, 0.5) !important;
            h2{
                color: $white;
            }
        }
    }
}