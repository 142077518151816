﻿.contact-section {
    .show-appointments {
        display: flex;
    }

    .telephone {
        a {
            text-decoration: none;
            display: flex;
            align-items: center;

            i, svg {
                font-size: 1.7rem;
                margin-right: 1rem;
            }

            span {
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
}

.hide-appointments {
    display: none;
}

.show-appointments {
    display: block;
}
